import React from 'react'
import { Link } from 'react-scroll'
import myphoto from '../img/myphoto22.png'
import tracehaut from '../img/tracehaut.png'

const Home = () => {
  return (
    <div>
      <section id='accueil'>
        <div className="header">
          <div className="introducing">
            <h1>Je m'appelle Daniel</h1>
            <p>Je suis développeur web front-end. Ma passion est de donner vie à votre idée et de la positionner parfaitement sur le web.</p>
            <div className="boutonsCall">
              <Link  
                to="projects"
                smooth={true}
                offset={-50}
                duration={800}
                className="call-oeuvres"
                >
                  Mes oeuvres
              </Link>
              <Link 
                to="contact"
                smooth={true}
                offset={-50}
                duration={800}
                className="call-contact"
                >
                  Me contacter
              </Link>
            </div>
          </div>
          <img src={myphoto} alt="" className="mybigphoto"/>
        </div>
        <img src={tracehaut} alt="" className="trace"/>
      </section>
    </div>
  )
}

export default Home
