import React from 'react'
import { FaCss3, FaGitAlt, FaNodeJs, FaReact, FaWordpress } from 'react-icons/fa'
import { ImHtmlFive } from 'react-icons/im'
import { IoLogoJavascript } from 'react-icons/io'
import tracebas from '../img/tracebas.png'

const About = () => {
  return (
    <div>
      <section id='about'>
        <img src={tracebas} alt="" className="trace"/>
        <h2>Qui suis-je?</h2>
        <p>Initialement diplômé en Economie, j'ai une carrière bien remplie dans le domaine des Assurances, ou malgré tout ma passion pour les TIC a toujours pris le dessus. De l'automatisation de certaines tâches au développement de quelques utilitaires, je me suis finalement lancé dans le développement web afin de mettre mes compétences au services de plusieurs et aider à concrétiser de grandes idées</p>
        <h3>Technologies maîtrisées</h3>
        <div className="skills">
          <ul>
            <li>
              <ImHtmlFive size="2em" fill='#e34c26' />
            </li>
            <li>
              <FaCss3 size="2em" fill="#264de4"/>
            </li>
            <li>
              <IoLogoJavascript size="2em" fill="#f0db4f"/>
            </li>
            <li>
              <FaReact size="2em" fill="#61DBFB"/>
            </li>
            <li>
              <FaNodeJs size="2em" fill="#303030"/>
            </li>
            <li>
              <FaWordpress size="2em" fill="#00749C"/>
            </li>
            <li>
              <FaGitAlt size="2em" fill="#f34f29"/>
            </li> 
          </ul>
        </div>
      </section>
    </div>
  )
}

export default About
