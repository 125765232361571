import { motion } from 'framer-motion'
import React from 'react'
import { animateScroll, Link } from 'react-scroll'
import './navBar.css'

const NavLinks = (props) => {

  const animateFrom = {opacity: 0, y: -40}
  const animateTo = {opacity: 1, y: 0}

  const maFonction = () => {
    animateScroll.scrollToTop()
    props.isMobile && props.closeMobileMenu()
  }
  return (
    <div>
      <ul className='navLinks'>
        <motion.li 
          initial={animateFrom}
          animate={animateTo}
          transition={{delay: 0.05}}
          >
          <Link className='link'
            // to="accueil"
            activeClass='active'
            spy={true}
            smooth={true}
            // offset={-70}
            duration={800}
            onClick={maFonction}
            >
              Accueil
          </Link>
        </motion.li>
        <motion.li 
          initial={animateFrom}
          animate={animateTo}
          transition={{delay: 0.10}}
          >
          <Link className='link'
            activeClass='active'
            to="about"
            smooth={true}
            offset={-50}
            duration={800}
            onClick={() => props.isMobile && props.closeMobileMenu()}
            >
              A propos
          </Link>
        </motion.li>
        <motion.li 
          initial={animateFrom}
          animate={animateTo}
          transition={{delay: 0.20}}
          >
          <Link className='link'
            activeClass='active' 
            to="projects"
            smooth={true}
            offset={-50}
            duration={800}
            onClick={() => props.isMobile && props.closeMobileMenu()}
            >
              Projets
          </Link>
        </motion.li>
        <motion.li 
          initial={animateFrom}
          animate={animateTo}
          transition={{delay: 0.30}}
          >
          <Link className='link'
            activeClass='active'
            to="contact"
            smooth={true}
            offset={-50}
            duration={800}
            onClick={() => props.isMobile && props.closeMobileMenu()}
            >
              Contact
          </Link>
        </motion.li>
      </ul>
    </div>
  )
}

export default NavLinks
