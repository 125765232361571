import React from 'react'

const ProjectsCard = (props) => {
  return (
    <>
      <div className="projects-card">
        
        <h3 className="project-title">{props.title}</h3>
        <img src={props.img} alt={props.alt} className="project-image"/>
        <p className="project-description">
          {props.description}
        </p>
        <a href={props.url} target="_blank" className="go-to-project" rel="noreferrer">Voir Projet</a>
        {console.log(props.img)}
      </div>
    </>
  )
}

export default ProjectsCard
