import React from 'react'

const Contact = () => {
  return (
    <div>
      <section id='contact'>
        <h2>Me contacter</h2>
        <form 
          className="contactForm"
          name="contact-version1"
          method="POST"
          data-netlify="true"
          // data-netlify-recaptcha="true"
          // onSubmit="submit"
        >
          <input type="hidden" name="form-name" value="contact-version1" />
          <label 
            htmlFor="nom" 
            className="name-label">Nom : 
          </label>
          <input 
            type="text" 
            name="nom"
            id="nom" 
            className="nameInput" 
            placeholder="Votre nom"
            required 
          /> 
          <label 
            htmlFor="prenom" 
            className="firstname-label">Prénoms :  
          </label>
          <input 
            type="text" 
            name="prenom"
            id="prenom" 
            className="firstNameInput" 
            placeholder="Votre prénom"
            required 
          />
          <label 
            htmlFor="email" 
            className="email-label">Email : 
          </label>
          <input 
            type="email" 
            name="email"
            id="email" 
            className="emailInput" 
            placeholder="Votre adresse email"
            required 
          /> 
          <label 
            htmlFor="message" 
            className="message-label">Message :  
          </label>
          <textarea 
            name="message" 
            id="message" 
            cols="20" 
            rows="7"
            placeholder="Votre message"
            required 
          ></textarea>
          <button 
            type="submit"
            className="submit">
              Envoyer
          </button>
        </form>
      </section>
    </div>
  )
}

export default Contact
