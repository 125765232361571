import React from 'react'
import MobileNavigation from './MobileNavigation'
import './navBar.css'
import Navigation from './Navigation'

const NavBar = () => {
  return (
    <div className='navbar'>
      <Navigation />
      <MobileNavigation />
    </div>
  )
}

export default NavBar
