import React from 'react'
import { animateScroll, Link } from 'react-scroll'
import logo from '../../img/logo.png'
import './navBar.css'
import NavLinks from './NavLinks'
const Navigation = () => {
  return (
    <div>
      <nav className='navigation'>
        <Link 
          onClick={() => animateScroll.scrollToTop()} 
        ><img className="logo" src={logo} alt='mylogo'/></Link>
        {/* <a href="danielpoidy.com">Daniel Poidy</a>  */}
        <NavLinks />
      </nav>
    </div>
  )
}

export default Navigation
