import React from 'react'
import myMobilePhoto from '../img/mymobilephoto.png'

const Hero = () => {
  return (
    <div className='hero'>
      <img src={myMobilePhoto} alt="" className="mymobilephoto"/>
    </div>
  )
}

export default Hero
