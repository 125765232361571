import React from 'react'
import { FaFacebook, FaGithub, FaTwitterSquare, FaYoutube } from 'react-icons/fa'
import { IoLogoLinkedin } from 'react-icons/io'

const Footer = () => {
  return (
    <div className='footer'>
      <div className="social-icons">
        <li>
          <a href="https://github.com/danielpoidy">
            <FaGithub size="1.5em" fill="#4078c0"/>
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/in/k-daniel-poidy-755a78126/">
            <IoLogoLinkedin size="1.5em" fill="#0e76a8"/>
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/channel/UCf7CQAo-eA3v2EvOsokU5fQ/featured">
            <FaTwitterSquare size="1.5em" fill="#1DA1F2"/>
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/channel/UCf7CQAo-eA3v2EvOsokU5fQ/featured">
            <FaYoutube size="1.5em" fill="#FF0000"/>
          </a>
        </li>
        <li>
          <a href="https://www.facebook.com/daniel.poidy.10/">
            <FaFacebook size="1.5em" fill="#4267B2"/>
          </a>
        </li>
      </div>
      <p className="copyright">Copyright@2021</p>
    </div>
  )
}

export default Footer
