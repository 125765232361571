import './App.css';
import NavBar from './components/navBar/NavBar'
import Home from './components/Home'
import Hero from './components/Hero'
import About from './components/About'
import Projects from './components/Projects'
import Contact from './components/Contact'
import Footer from './components/Footer'

function App() {
  return (
    <>
      <NavBar />
      <div className="App">
        <Hero />
        <Home />
        <About />
        <Projects />
        <Contact />
      </div>
      <Footer />
    </>
  )
}

export default App;
