import React from 'react'
import ProjectsCard from './ProjectsCard'

const Projects = () => {

  let projets = [
    {
      title: "Movie Search",
      image: "img/react-movie-search22.png",
      alt: "movie search app thumbnail",
      description: "Une application pour rechercher tous vos films et séries préférées. Réalisée avec React js",
      url: "https://danickdevmoviesearch.netlify.app/"
    },
    {
      title: "Jeu d'arcade",
      image: "img/jeu-arcade.png",
      alt: "pacman game thumbnail",
      description: "Jeu d'arcade pour se détendre. Réalisée avec HTML, CSS et Vanila JavaScript",
      url: "https://jeu-arcade.netlify.app/"
    },
    {
      title: "Liste des tâches",
      image: "img/todo.png",
      alt: "Todo App",
      description: "Simple application pour lister ses tâches. Réalisée avec HTML, CSS et Vanila JavaScript",
      url: "https://mintodoapp.netlify.app/"
    }
  ]
  return (
    <div>
      <section id='projects'>
        <h2>Quelques projets réalisés</h2>
        <div className="projects-grid">
          {projets.map(project => <ProjectsCard title={project.title}
                                                img={project.image}
                                                description={project.description}
                                                url={project.url}
                                                />)}
        </div>
      </section>
    </div>
  )
}

export default Projects
