import React, { useState } from 'react'
import { CgCloseO, CgMenuRound } from 'react-icons/cg'
import './navBar.css'
import NavLinks from './NavLinks'

const MobileNavigation = () => {

  const [open, setOpen] = useState(false)

const openedHamburgerIcon =<CgMenuRound 
  className='hamburger' size='2em' color="blue"
  onClick={() => setOpen(!open)} />

const closedHamburgerIcon =<CgCloseO 
  className='hamburger' size='2em'
  onClick={() => setOpen(!open)} />

const closeMobileMenu = () => setOpen(false)

  return (
    <div>
      
      <nav className="mobileNavigation">
        {/* <Link onClick={animateScroll.scrollToTop()}><img className="logo" src={logo} alt='mylogo'/></Link> */}
        {open ? closedHamburgerIcon : openedHamburgerIcon}
        {open ? <NavLinks isMobile={true} closeMobileMenu={closeMobileMenu} /> : null}
      </nav>
    </div>
  )
}

export default MobileNavigation
